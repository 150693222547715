var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "regular-tables", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Simple Tables", link: "components/simple-tables" }
      }),
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3",
          attrs: {
            inline: "",
            icon: "mdi-clipboard-text",
            title: "Simple Table"
          }
        },
        [
          _c("v-simple-table", [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "primary--text" }, [
                  _vm._v("\n            ID\n          ")
                ]),
                _c("th", { staticClass: "primary--text" }, [
                  _vm._v("\n            Name\n          ")
                ]),
                _c("th", { staticClass: "primary--text" }, [
                  _vm._v("\n            Country\n          ")
                ]),
                _c("th", { staticClass: "primary--text" }, [
                  _vm._v("\n            City\n          ")
                ]),
                _c("th", { staticClass: "text-right primary--text" }, [
                  _vm._v("\n            Salary\n          ")
                ])
              ])
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("1")]),
                _c("td", [_vm._v("Dakota Rice")]),
                _c("td", [_vm._v("Niger")]),
                _c("td", [_vm._v("Oud-Turnhout")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $36,738\n          ")
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("2")]),
                _c("td", [_vm._v("Minverva Hooper")]),
                _c("td", [_vm._v("Curaçao")]),
                _c("td", [_vm._v("Sinaas-Waas")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $23,789\n          ")
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("3")]),
                _c("td", [_vm._v("Sage Rodriguez")]),
                _c("td", [_vm._v("Netherlands")]),
                _c("td", [_vm._v("Baileux")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $56,142\n          ")
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("4")]),
                _c("td", [_vm._v("Philip Chaney")]),
                _c("td", [_vm._v("Korea, South")]),
                _c("td", [_vm._v("Overland Park")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $38,735\n          ")
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("5")]),
                _c("td", [_vm._v("Doris Greene")]),
                _c("td", [_vm._v("Malawi")]),
                _c("td", [_vm._v("Feldkirchen in Kärnten")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $63,542\n          ")
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("6")]),
                _c("td", [_vm._v("Mason Porter")]),
                _c("td", [_vm._v("Chile")]),
                _c("td", [_vm._v("Gloucester")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $78,615\n          ")
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _c("div", { staticClass: "py-3" }),
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3",
          attrs: {
            color: "success",
            dark: "",
            icon: "mdi-clipboard-plus",
            inline: "",
            title: "Table on Dark Background"
          }
        },
        [
          _c("v-simple-table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("ID")]),
                _c("th", [_vm._v("Name")]),
                _c("th", [_vm._v("Country")]),
                _c("th", [_vm._v("City")]),
                _c("th", { staticClass: "text-right" }, [
                  _vm._v("\n            Salary\n          ")
                ])
              ])
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("1")]),
                _c("td", [_vm._v("Dakota Rice")]),
                _c("td", [_vm._v("Niger")]),
                _c("td", [_vm._v("Oud-Turnhout")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $36,738\n          ")
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("2")]),
                _c("td", [_vm._v("Minverva Hooper")]),
                _c("td", [_vm._v("Curaçao")]),
                _c("td", [_vm._v("Sinaas-Waas")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $23,789\n          ")
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("3")]),
                _c("td", [_vm._v("Sage Rodriguez")]),
                _c("td", [_vm._v("Netherlands")]),
                _c("td", [_vm._v("Baileux")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $56,142\n          ")
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("4")]),
                _c("td", [_vm._v("Philip Chaney")]),
                _c("td", [_vm._v("Korea, South")]),
                _c("td", [_vm._v("Overland Park")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $38,735\n          ")
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("5")]),
                _c("td", [_vm._v("Doris Greene")]),
                _c("td", [_vm._v("Malawi")]),
                _c("td", [_vm._v("Feldkirchen in Kärnten")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $63,542\n          ")
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("6")]),
                _c("td", [_vm._v("Mason Porter")]),
                _c("td", [_vm._v("Chile")]),
                _c("td", [_vm._v("Gloucester")]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("\n            $78,615\n          ")
                ])
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }