import { render, staticRenderFns } from "./RegularTables.vue?vue&type=template&id=1c5e170c&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VContainer,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("D:\\_Dr\\_git\\poliglotiki-front\\crm-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c5e170c')) {
      api.createRecord('1c5e170c', component.options)
    } else {
      api.reload('1c5e170c', component.options)
    }
    module.hot.accept("./RegularTables.vue?vue&type=template&id=1c5e170c&", function () {
      api.rerender('1c5e170c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/tables/RegularTables.vue"
export default component.exports